import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import Swiper360 from 'components/modules/Swiper360';
import ImageUrl from 'components/base/ImageUrl';

// Controlling state from parent,
// https://stackoverflow.com/a/55889638/1726887
const SwiperImages = ({ data, alt = '' }) => {
  const [swiper, updateSwiper] = useState(null);
  const [playing, setPlayingState] = useState(false);

  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 600,
    simulateTouch: false,
    on: {
      slideChange: function() {
        // larger carousel changes
        setPlayingState(false);
      }
    },
    keyboard: {
      enabled: false
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  const onThumbClick = index => {
    swiper.slideTo(index);
  };

  return (
    <div className="cf">
      {data._360_images.length > 0 ? (
        <Swiper {...params} getSwiper={updateSwiper}>
          <div className="swiper-slide">
            <Swiper360
              data={data}
              playing={playing}
              setPlayingState={setPlayingState}
            />
          </div>
          {data.images.map((item, index) => {
            return (
              <div className="swiper-slide" key={index}>
                {/* This is placeholder */}
                <div className="swiper-slide__blurup relative">
                  <div className="swiper-slide__aspect-ratio-wrapper relative">
                    <div
                      className="swiper-slide__aspect-ratio relative"
                      style={{
                        paddingBottom: `${(item.height / item.width) * 100}%`
                      }}
                    >
                      <ImageUrl
                        src={item.url}
                        sizes={{
                          lg: 1200,
                          xl: 1200,
                          xxl: 1200
                        }}
                        alt={alt}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Swiper>
      ) : (
        <>
          {data.images.length > 1 ? (
            <Swiper {...params} getSwiper={updateSwiper}>
              {data.images.map((item, index) => {
                return (
                  <div className="swiper-slide__blurup relative" key={index}>
                    <div className="swiper-slide__aspect-ratio-wrapper relative">
                      <div
                        className="swiper-slide__aspect-ratio relative"
                        style={{
                          paddingBottom: `${(item.height / item.width) * 100}%`
                        }}
                      >
                        <ImageUrl
                          src={item.url}
                          sizes={{
                            lg: 1200,
                            xl: 1200,
                            xxl: 1200
                          }}
                          alt={alt}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Swiper>
          ) : (
            <div className="flex w-100 items-center justify-center">
              <div className="swiper-slide__blurup relative">
                <div className="swiper-slide__aspect-ratio-wrapper relative">
                  <div
                    className="swiper-slide__aspect-ratio relative"
                    style={{
                      paddingBottom: `${(data.images[0].height /
                        data.images[0].width) *
                        100}%`
                    }}
                  >
                    <ImageUrl
                      src={data.images[0].url}
                      sizes={{
                        lg: 1200,
                        xl: 1200,
                        xxl: 1200
                      }}
                      alt={alt}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {((data._360_images.length > 0 && data.images.length > 0) ||
        data.images.length > 1) && (
        <ul className="swiper-thumbs items-center justify-center flex w-100 w-50-md w-25-lg fn fr-md mt3">
          {data._360_images.length > 0 ? (
            <button
              className="mh2 swiper-thumbs__thumbnail pointer"
              onClick={() => {
                onThumbClick(0);
              }}
            >
              <ImageUrl
                src={data._360_images[0].url}
                sizes={{
                  sm: 200,
                  md: 300,
                  lg: 400,
                  xl: 400,
                  xxl: 400
                }}
              />
            </button>
          ) : null}
          {data.images.map((item, index) => {
            return (
              <button
                className="mh2 swiper-thumbs__thumbnail pointer"
                key={index}
                onClick={() => {
                  let incrementer = data._360_images.length > 0 ? 1 : 0;
                  onThumbClick(index + incrementer);
                }}
              >
                <ImageUrl
                  src={item.url}
                  sizes={{
                    sm: 200,
                    md: 300,
                    lg: 400,
                    xl: 400,
                    xxl: 400
                  }}
                />
              </button>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SwiperImages;
