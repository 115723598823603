/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import ImageUrl from 'components/base/ImageUrl';
import IconPlay from 'svg/icn-play';
import IconPlayOutline from 'svg/icn-play-outline';

// Controlling state from parent,
// https://stackoverflow.com/a/55889638/1726887
const Swiper360 = ({ data, playing, setPlayingState, alt = '' }) => {
  const [playerHovered, setPlayerHoveredState] = useState(false);
  const [_360_swiper, _360_updateSwiper] = useState(null);
  const [rangeValue, setRangeValueState] = useState(0);
  let playInterval;

  const _360_params = {
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 0,
    effect: 'fade',
    simulateTouch: false,
    on: {
      slideChange: function() {
        setRangeValueState(this.activeIndex);
      }
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false
    }
  };

  const onRangeChange = e => {
    setPlayingState(false);
    setRangeValueState(e.target.value);
    _360_swiper.slideTo(parseInt(e.target.value, 10));
  };

  const on360Play = e => {
    setPlayingState(!playing);
  };

  const autoPlay = () => {
    if (playing) {
      _360_swiper.slideTo(
        (_360_swiper.activeIndex + 1) % _360_swiper.slides.length
      );
    }
  };

  useEffect(() => {
    playInterval = setInterval(autoPlay, 100);
    return () => clearInterval(playInterval);
  }, [playInterval, playing, _360_swiper]);

  return (
    <div className="w-100">
      <Swiper {..._360_params} getSwiper={_360_updateSwiper}>
        {data._360_images.map((item, index) => {
          return (
            <div className="swiper-slide-fade" key={index}>
              <div className="swiper-slide__blurup relative">
                <div className="swiper-slide__aspect-ratio-wrapper relative">
                  <div
                    className="swiper-slide__aspect-ratio relative"
                    style={{
                      paddingBottom: `${(item.height / item.width) * 100}%`
                    }}
                  >
                    <ImageUrl
                      src={item.url}
                      className="swiper-slide__image"
                      sizes={{
                        lg: 1200,
                        xl: 1200,
                        xxl: 1200
                      }}
                      alt={alt}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Swiper>
      <div className="Scrubber tc flex items-center justify-center mt4">
        <button
          className="pa2 Scrubber__play"
          onClick={on360Play}
          onMouseOver={() => {
            setPlayerHoveredState(true);
          }}
          onFocus={() => {
            setPlayerHoveredState(true);
          }}
          onMouseOut={() => {
            setPlayerHoveredState(false);
          }}
          onBlur={() => {
            setPlayerHoveredState(false);
          }}
        >
          {playing || playerHovered ? <IconPlayOutline /> : <IconPlay />}
        </button>
        <div className="mh2 w-100 w-33-md">
          <input
            className="db"
            type="range"
            name="_360_range"
            min="0"
            max={data._360_images.length - 1}
            value={rangeValue}
            onChange={onRangeChange}
          />
        </div>
        <label
          className="Scrubber__label db mh2 body-copy"
          htmlFor="_360_range"
        >
          {parseInt(rangeValue, 10) + 1}/{data._360_images.length}
        </label>
      </div>
    </div>
  );
};

export default Swiper360;
