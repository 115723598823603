import React from 'react';

const IconPlay = ({ className = '' }) => {
  return (
    <svg className={className} width="10px" height="11px" viewBox="0 0 10 11">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-693.000000, -778.000000)" fill="#000000">
          <g transform="translate(693.000000, 771.000000)">
            <polygon
              transform="translate(4.875000, 12.375000) rotate(-270.000000) translate(-4.875000, -12.375000) "
              points="4.875 7.5 9.90789474 17.25 -0.157894737 17.25"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconPlay;
