import React, { useState } from 'react';
import SwiperImages from 'components/modules/SwiperImages';
import Swiper360 from 'components/modules/Swiper360';

const BlockItemImages = ({ data, alt = '' }) => {
  const [playing, setPlayingState] = useState(false);
  return (
    <section className="block relative mv5">
      {data.images.length > 0 ? (
        <SwiperImages data={data} alt={alt} />
      ) : (
        <Swiper360
          data={data}
          playing={playing}
          setPlayingState={setPlayingState}
          alt={alt}
        />
      )}
    </section>
  );
};

export default BlockItemImages;
