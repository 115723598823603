import React, { useState } from 'react';
import classnames from 'classnames';
import Link from 'components/base/Link';
import { slug } from 'components/utils/helpers';

const Breadcrumbs = ({ data, className = '', types = [] }) => {
  const [expanded, setExpanded] = useState(false);
  let timeout = null;
  return (
    <ul // eslint-disable-line
      className={classnames(`${className} Breadcrumb body-copy`, {
        'Breadcrumb-expanded': expanded
      })}
      onMouseLeave={() => {
        timeout = setTimeout(() => {
          setExpanded(false);
        }, 1000);
      }}
      onMouseEnter={() => {
        clearTimeout(timeout);
      }}
    >
      <li className="dib relative Breadcrumb__item">
        <Link
          to={`/collection`}
          className="gradient-hover bn"
          onMouseEnter={() => {
            clearTimeout(timeout);
            setExpanded(true);
          }}
        >
          Categories
        </Link>
      </li>
      {types.map((item, index) => {
        const link = types
          .slice(0, index + 1)
          .map(item => slug(item))
          .join('/');
        return (
          <li className="dib relative Breadcrumb__item" key={index}>
            <Link to={`/collection/${link}`} className="gradient-hover bn">
              {item}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumbs;
