import React from 'react';

const IconPlayOutline = ({ className = '' }) => {
  return (
    <svg className={className} width="10px" height="11px" viewBox="0 0 10 11">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-924.000000, -1037.000000)"
          stroke="#000000"
          strokeWidth="1"
        >
          <g transform="translate(924.000000, 1028.000000)">
            <path
              d="M5.5,10.3484907 L0.404508497,20.5394737 L10.5954915,20.5394737 L5.5,10.3484907 Z"
              transform="translate(5.500000, 15.289474) rotate(-270.000000) translate(-5.500000, -15.289474)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconPlayOutline;
