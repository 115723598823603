import React from 'react';
import classnames from 'classnames';
import Layout from 'components/Layout';
import BlockLinksList from 'components/modules/BlockLinksList';
import BlockItemImages from 'components/modules/BlockItemImages';
import Breadcrumbs from 'components/modules/Breadcrumbs';
import SEO from 'components/site/SEO';

const Item = ({ pageContext }) => {
  const {
    name,
    accessionNumber,
    values,
    images,
    _360_images,
    taxa,
    baseTaxa,
    displayImage
  } = pageContext;
  const taxaArr = taxa.split('__');

  return (
    <Layout pageTitle="Catalog" className="Item" link="/collection">
      <SEO
        title={name}
        imageURL={
          displayImage ? `${displayImage}?w=1200&h=600&fm=jpg&q=70` : null
        }
        description={values
          .map(item => `${item.name}: ${item.data}`)
          .join(', ')}
      />
      <article className="container container--thin">
        <section className="block pv3">
          {/* the breadcrumbs */}
          <Breadcrumbs types={taxaArr} />
        </section>
        {/* The item images */}
        {(_360_images.length > 0 || images.length > 0) && (
          <BlockItemImages
            data={{ _360_images: _360_images, images: images }}
            alt={name}
          />
        )}
      </article>
      <article className="container container--thin container--boxed">
        {/* the page header block */}
        <section className="block bb pv3">
          <h3 className="body-header ph3 ph4-xl ttu">{name}</h3>
        </section>
        {/* the accession number block */}
        <section className="block bb pv3">
          <h3 className="body-copy ph3 ph4-xl">
            Accession Number : {accessionNumber}
          </h3>
        </section>
        {/* the item info list block */}
        <section className="block">
          <ul className="body-copy">
            {values.map((value, index) => {
              return (
                <li
                  className={classnames('pv3 ph4 ph4-xl', {
                    bb: index < values.length
                  })}
                  key={value.name}
                >
                  {value.name}: {value.data}
                </li>
              );
            })}
          </ul>
        </section>
        {/* Links list block */}
        <BlockLinksList taxa={baseTaxa} />
      </article>
    </Layout>
  );
};

export default Item;
